/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable eqeqeq */
/* eslint-disable import/order */
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import colors from '../../../assets/colors'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import {
  mdiBookEditOutline,
  mdiCommentTextMultiple,
  mdiCreditCardEditOutline,
  mdiCreditCardSettingsOutline,
  mdiInvoiceTextEditOutline,
  mdiSignature,
} from '@mdi/js'
import PaymentHistory from './PaymentHistory'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlanDetails } from '../SubscriptionActions'
import { renderRoute } from '../../../util/utils'
import { Route } from 'react-router-dom'
import CreditCardForm from './Forms/CreditCardForm'
import PlanDetails from './Forms/PlanDetails'
import PaymentMethod from './Forms/PaymentMethod'
import CardsForm from './Forms/CardsForm'
import InvoiceDetails from './InvoiceDetailsModal'

const Subscription = () => {
  const permissions = useSelector(state => state.auth.permissions)
  const planDetails = useSelector(state => state.subscription.planDetails)

  const [value, setValue] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPlanDetails())
  }, [value])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="h4"
          style={{ fontSize: '20px' }}
          color={colors.primary}
        >
          Minha Assinatura
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          style={{
            padding: '8px 0',
            borderTop: '1px dashed',
            borderColor: colors.textSecondary,
            borderBottom: '1px dashed',
            borderBottomColor: colors.textSecondary,
            color: colors.textPrimary,
            fontSize: '14px',
          }}
        >
          {/* aguardando cadastro */}
          {planDetails?.status?.var_id == 10 &&
            'Complete os dados de pagamento para iniciar sua assinatura.'}

          {planDetails?.status?.var_id == 40 &&
            'Fatura com falha no pagamento, edite o método de pagamento para reprocessar sua fatura. Ou então ficará em débito'}

          {![10, 40].includes(planDetails?.status?.var_id) &&
            'Visualizar os dados de pagamento.'}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Tabs>
          <Tab
            label="Plano"
            icon={mdiBookEditOutline}
            value={value}
            setValue={setValue}
            tabIndex={0}
            removeNavigation={false}
          />
          <Tab
            label="Método de pagamento"
            icon={mdiCreditCardSettingsOutline}
            value={value}
            setValue={setValue}
            tabIndex={1}
            removeNavigation={false}
          />
          <Tab
            label="Cartões"
            icon={mdiCreditCardEditOutline}
            value={value}
            setValue={setValue}
            tabIndex={2}
            removeNavigation={false}
          />
          <Tab
            label="Histórico de pagamento"
            icon={mdiCommentTextMultiple}
            value={value}
            setValue={setValue}
            tabIndex={3}
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      {value == 0 && (
        <Grid item xs={12}>
          <PlanDetails />
        </Grid>
      )}

      {value == 1 && (
        <Grid item xs={12}>
          <PaymentMethod />
        </Grid>
      )}

      {value == 2 && (
        <Grid item xs={12}>
          <CardsForm />
        </Grid>
      )}

      {value == 3 && (
        <Grid item xs={12}>
          <PaymentHistory />
        </Grid>
      )}

      {renderRoute(['gerency-update-laundry-client'], permissions) && (
        <Route
          path="/subscription/credit-card/new"
          component={CreditCardForm}
        />
      )}

      {renderRoute(['gerency-update-laundry-client'], permissions) && (
        <Route
          path="/subscription/credit-card/:id"
          component={CreditCardForm}
        />
      )}
    </Grid>
  )
}

export default Subscription
