import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchInvoice,
  fetchInvoices,
  reprocessInvoice,
} from '../SubscriptionActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { exportFormatedMoney } from '../../../util/utils'
import Icon from '@mdi/react'
import { mdiEyeOutline, mdiInvoiceTextEditOutline, mdiRestore } from '@mdi/js'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import InvoiceDetails from './InvoiceDetailsModal'
import Filter from './PaymentHistoryFilter'
import ReprocessInvoice from './ReprocessInvoiceModal'
import { useConfirm } from 'material-ui-confirm'

const PaymentHistory = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const classes = filterStylesComponents()

  const invoices = useSelector(state => state.subscription.invoices)
  const planDetails = useSelector(state => state.subscription.planDetails)

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }

  useEffect(() => {
    dispatch(fetchInvoices())
  }, [])

  const handleViewInvoice = id => {
    dispatch(fetchInvoice(id))
    handleClose()
  }

  const handleReprocessInvoice = id => {
    confirm({
      description: `Deseja realmente reprocessar a fatura?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      await dispatch(reprocessInvoice(id))
      dispatch(fetchInvoices(id))
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Filter />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCellHeader>Plano</StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  Valor
                </StyledTableCellHeader>
                <StyledTableCellHeader>
                  Método de Pagamento
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center" sortDirection={false}>
                  Data de Referência
                </StyledTableCellHeader>
                <StyledTableCellHeader>
                  Data de Vencimento
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  Status
                </StyledTableCellHeader>
                <StyledTableCellHeader align="right">
                  Ações
                </StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((item, index) => (
                <TableRow key={index}>
                  <StyledTableCellBody>{item.client_plan}</StyledTableCellBody>
                  <StyledTableCellBody align="center">
                    R$ {exportFormatedMoney(item.value)}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {item.payment_method}
                  </StyledTableCellBody>
                  <StyledTableCellBody align="center">
                    {item.reference_date}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{item.due_at}</StyledTableCellBody>
                  <StyledTableCellBody align="center">
                    {item?.status_value}
                  </StyledTableCellBody>
                  <StyledTableCellBody align="right">
                    {/* boleto */}
                    {item?.payment_method_id == 20 && (
                      <IconButton
                        style={{ padding: '0px ' }}
                        onClick={() => {
                          handleViewInvoice(item?.id)
                        }}
                      >
                        <Icon path={mdiEyeOutline} size={1} />
                      </IconButton>
                    )}
                    {/* cartao de credito */}
                    {item?.payment_method_id == 10 &&
                      item?.status_id == 40 &&
                      planDetails?.status?.var_id != 40 && (
                        <IconButton
                          style={{ padding: ' 0px' }}
                          onClick={() => {
                            handleReprocessInvoice(item?.id)
                          }}
                        >
                          <Icon path={mdiRestore} size={1} />
                        </IconButton>
                      )}
                  </StyledTableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <InvoiceDetails open={open} handleClose={handleClose} />
      {/* <ReprocessInvoice open handleClose={handleClose} /> */}
    </Grid>
  )
}

export default PaymentHistory
