import { Box, Card, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import colors from '../../../assets/colors'

import { useDispatch, useSelector } from 'react-redux'

import { Route } from 'react-router-dom/cjs/react-router-dom'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import Filter from './Filter'
import { mdiCurrencyUsd, mdiCurrencyUsdOff } from '@mdi/js'
import List from './List'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
})

const Dre = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [value, setValue] = useState(0)
  const [typeGeneric, setTypeGeneric] = useState('Despesa')

  useEffect(() => {
    setTypeGeneric(value == 1 ? 'Receita' : 'Despesa')
  }, [value])

  return (
    <>
      <BaseCreatePage page="Financeiro" path="Dre" pageLink="/dre" hiddenReturn>
        <Grid container spacing="3" style={{ marginTop: '4px' }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tabs>
                  <Tab
                    label="Despesas"
                    value={value}
                    setValue={setValue}
                    tabIndex={0}
                    iconColor={colors.error}
                    removeNavigation={false}
                    icon={mdiCurrencyUsdOff}
                  />
                  <Tab
                    label="Receitas"
                    value={value}
                    setValue={setValue}
                    tabIndex={1}
                    iconColor={colors.sucess}
                    removeNavigation={false}
                    icon={mdiCurrencyUsd}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Filter typeGeneric={typeGeneric} />
          </Grid>

          <Grid item xs={12}>
            <List type={typeGeneric} />
          </Grid>
        </Grid>
      </BaseCreatePage>
    </>
  )
}

export default Dre
