import { FETCH_DRE } from './DreActions'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DRE.SUCCESS:
      return {
        ...state,
      }
    default:
      return state
  }
}
