/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react'
import {
  FormControlLabel,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from '@material-ui/core'
import { IconButton, makeStyles } from '@material-ui/core'
import { Grid, Paper, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { PhotoCamera } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import axios from 'axios'
import ufs from '../../../../util/uf'
import { isCpfCnpjValid } from '../../../../util/cpf_cnpj_validator'
import config from '../../../../config'
import colors from '../../../../assets/colors'
import Icon from '@mdi/react'
import { mdiProgressUpload } from '@mdi/js'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '.5rem',
    // border: '1px solid #539fdd',
  },
  row: {
    width: '100%',
    padding: '.5rem',
  },
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  logo: {
    objectFit: 'cover',
    width: '169px',
    maxHeight: '60px',
    borderRadius: '10px',
  },
  logoValidate: {
    fontSize: '12px',
    color: colors.graylight,
  },
})

const FormGroupLaundry = ({ form, setForm, handleChange }) => {
  const classes = useStyles()

  const [mask, setMask] = useState('999.999.999-99')

  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: 'Digite um CPF ou CNPJ',
  })

  const [logoValidate, setLogoValidate] = useState({
    invalidType: '',
  })

  const inputRef = useRef()

  const handlePhoneChange = e => {
    const { name, value } = e.target
    const phoneWithoutMask = value.replace(/\D/g, '')

    setForm({
      ...form,
      [name]: phoneWithoutMask,
    })
  }

  const handleChangeLogo = event => {
    const { files } = event.target

    if (!files[0]) return

    const image = event.target.files[0]
    const load = new FileReader()

    load.onload = event => {
      setForm(prev => ({
        ...prev,
        logo: files[0],
        logoPath: event.target.result,
      }))
    }

    if (image) load.readAsDataURL(image)

    if (!['image/png', 'image/jpeg'].includes(files[0].type)) {
      setForm(prev => ({
        ...prev,
        logo: null,
      }))

      return setLogoValidate({
        ...logoValidate,
        invalidType: 'Tipo de arquivo inválido',
        logoPath: null,
      })
    }

    setForm(prev => ({
      ...prev,
      logo: files[0],
    }))

    setLogoValidate({
      ...logoValidate,
      invalidType: '',
    })
  }

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  const buscaCep = async cep => {
    const currentCep = cep?.replace(/\D/g, '')

    if (!currentCep?.length == 8) return

    try {
      const resp = await axios
        .get(`https://viacep.com.br/ws/${currentCep}/json/`)
        .then(response => response?.data)

      if (!resp) return

      setForm({
        ...form,
        city: resp?.localidade,
        uf: resp?.uf,
        street: resp?.logradouro,
        neighborhood: resp?.bairro,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeCpf = e => {
    const { name, value } = e.target

    if (value?.length >= 15) return setMask('99.999.999/9999-99')

    setMask('999.999.999-999')
  }

  const handleValidCpfCnpj = () => {
    const { cpf_cnpj: value } = form

    const { length } = value

    if (length < 14) {
      setCpfCnpj({
        ...cpfCnpj,
        error: false,
        text: 'Digite um CPF ou CNPJ válido',
      })
    }

    // validate if cnpj
    if (length == 18) {
      if (!isCpfCnpjValid(value)) {
        return setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CNPJ inválido',
        })
      }
    }

    if (!isCpfCnpjValid(value)) {
      return setCpfCnpj({
        ...cpfCnpj,
        error: true,
        text: 'CPF inválido',
      })
    }

    setCpfCnpj({
      ...cpfCnpj,
      error: false,
      text: '',
    })
  }

  useEffect(() => {
    if (form?.cpf_cnpj >= 15) return setMask('99.999.999/9999-99')
  }, [form])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              {form?.logoPath && (
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={form?.logoPath}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              {form?.logo && !form?.logoPath && (
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={`data:image/png;base64,${form?.logo}`}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid item md={2}>
                <input
                  ref={inputRef}
                  name="logo"
                  type="file"
                  accept="image/*"
                  onChange={handleChangeLogo}
                  style={{ display: 'none' }}
                />
                <IconButton
                  onClick={handleChooseFile}
                  className={classes.uploadLogo}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon path={mdiProgressUpload} size={1} />
                      Upload Logo
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.logoValidate}>
                        {logoValidate?.invalidType || 'Logo (169 x 32)'}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} md={6} xs={12}>
                <TextField
                  label="Nome"
                  name="name"
                  required
                  value={form?.name}
                  onChange={handleChange}
                  fullWidth
                  className={classes?.textField}
                />
              </Grid>

              <Grid item sm={6} md={6} xs={12}>
                <TextField
                  label="E-mail"
                  fullWidth
                  name="email"
                  value={form?.email}
                  onChange={handleChange}
                  className={classes.textField}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={5} xs={12} lg={4}>
            <ReactInputMask
              mask="99.999-999"
              name="cep"
              maskChar=""
              value={form?.cep}
              required
              onChange={handleChange}
              onBlur={() => buscaCep(form?.cep)}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField
              label="Rua"
              name="street"
              fullWidth
              value={form?.street}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField
              label="Número"
              name="number"
              fullWidth
              required
              value={form?.number}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} lg={4}>
              <TextField
                name="neighborhood"
                label="Bairro"
                onChange={handleChange}
                fullWidth
                required
                value={form?.neighborhood}
              />
            </Grid>

            <Grid item sm={12} xs={12} lg={4}>
              <TextField name="complement" label="Complemento" fullWidth />
            </Grid>

            <Grid item sm={12} xs={12} lg={4}>
              <TextField
                name="city"
                label="Cidade"
                onChange={handleChange}
                fullWidth
                required
                value={form?.city}
              />
            </Grid>

            <Grid item sm={12} xs={12} lg={2}>
              <InputLabel id="demo-simple-select-required-label">UF</InputLabel>
              <Select
                value={form?.uf}
                fullWidth
                name="uf"
                required
                onChange={handleChange}
              >
                <MenuItem value="">Selecione...</MenuItem>
                {ufs?.map((uf, index) => {
                  return (
                    <MenuItem key={index} value={uf}>
                      {uf}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={3}>
              <ReactInputMask
                mask="(99) 99999-9999"
                placeholder="(__) _____-____"
                name="phone"
                maskChar=""
                value={form?.phone}
                onChange={handlePhoneChange}
              >
                {inputProps => (
                  <TextField {...inputProps} label="Telefone" fullWidth />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={3}>
              <ReactInputMask
                key={`ìnput-phone-${form?.cell_phone}`}
                mask="(99) 99999-9999"
                placeholder="(__) _____-____"
                name="cell_phone"
                maskChar=""
                value={form?.cell_phone}
                onChange={handlePhoneChange}
              >
                {inputProps => (
                  <TextField {...inputProps} label="Celular" fullWidth />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item sm={12} xs={12} md={6} lg={4} xl={4}>
              <ReactInputMask
                mask={mask}
                maskChar=""
                name="cpf_cnpj"
                value={form?.cpf_cnpj}
                onChange={e => {
                  handleChangeCpf(e)
                  handleChange(e)
                }}
                onBlur={e => handleValidCpfCnpj(e)}
                required
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    label="CPF/CNPJ"
                    fullWidth
                    error={cpfCnpj?.error}
                    helperText={cpfCnpj?.text}
                  />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '13px' }}>
              <TextField
                placeholder="Descrição"
                name="description"
                multiline
                value={form?.description}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormGroupLaundry
