import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiBarcodeScan, mdiFilePdfBox } from '@mdi/js'
import copy from 'copy-to-clipboard'
import { SNACKBAR } from '../../main/MainActions'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    gap: '2px',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    justifyContent: 'space-between',
    fontSize: '20px',
  },
})

const ReprocessInvoice = ({ open, handleClose }) => {
  const classes = useStyles()
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.subscription.showInvoice)
  const paymentMethods = useSelector(state => state.subscription.paymentMethods)
  const creditCards = useSelector(state => state.subscription.creditCards)

  const handleCopy = async value => {
    try {
      await copy(value)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'Código copiado com sucesso!' },
      })
    } catch (error) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Erro ao copiar o codigo!' },
      })
    }
  }

  console.log('')

  return (
    <BaseFilterModal
      open={open}
      hiddenHelpButton
      handleOpen={handleClose}
      title={'Reprocessar Fatura'}
    >
      <Grid item xs={12} style={{ width: '350px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Para reprocessar uma fatura que falhou, você deverá escolher o
              método de pagamento novamente. <br />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              name="payment_method_id"
              fullWidth
              // value={form?.payment_method_id}
              // onChange={handleChange}
              // disabled={readOnly}
            >
              <MenuItem value="">Selecione...</MenuItem>
              {paymentMethods?.map((paymentMethod, index) => (
                <MenuItem value={paymentMethod?.var_id} key={index}>
                  {paymentMethod?.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className={filterStyles.areaButtons}
            >
              <IconButton onClick={() => {}} className={filterStyles.btnSalvar}>
                Salvar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default ReprocessInvoice
