import {
  Grid,
  makeStyles,
  Box,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Button,
} from '@material-ui/core'
import React, { useState } from 'react'
import CountUp from 'react-countup'

import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../assets/colors'
import { renderRoute } from '../../../../util/utils'
import { useConfirm } from 'material-ui-confirm'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { mdiCreditCard, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { destroyCard } from '../../SubscriptionActions'
import brandVisaPath from '../../../../../public/card_brands/visa_brand.png'
import brandMastercardPath from '../../../../../public/card_brands/mastercard_brand.png'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  divider: {
    borderLeft: `1px solid ${colors.graylight}`,

    [theme.breakpoints.down('xs')]: {
      borderLeft: `0`,
      borderBottom: `1px solid ${colors.graylight}`,
    },
  },
  creditCard: {
    border: `1px solid ${colors.graylight}`,
    width: '170px',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    minHeight: '97px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '1 !important',
    },
    animation: '$fadeIn .4s ease-in-out',
  },
  creditCardInfo: {
    width: '100%',
    height: '80%',
    padding: '12px',
    color: colors.gray,
  },
  creditCardActions: {
    backgroundColor: colors.graylight,
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
    margin: '0',
    borderRadius: '0 0 10px 10px',
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  newCreditCard: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.graylight,
  },
  btnNewCreditCard: {
    borderRadius: 0,
    backgroundColor: 'inherit',
    width: '100%',
    height: '90px',
    fontSize: '11px',
    color: colors.gray,
    lineHeight: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: '.6',
    },
  },
  btnCreditCardActions: {
    width: 'auto',
    padding: 0,
    margin: 0,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    textTransform: 'capitalize',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
      backgroundColor: colors.graylight,
    },
  },
  showCards: {
    outline: 'none',
    backgroundColor: colors.primary,
    color: 'white',
    height: '100%',
    borderRadius: '8px',
    padding: '4px',
    width: '100%',
    cursor: 'pointer',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
    },
  },
}))

const CardsForm = ({}) => {
  const classes = useStyles()

  const creditCards = useSelector(state => state.subscription.creditCards)
  const permissions = useSelector(state => state.auth.permissions)

  const confirm = useConfirm()
  const dispatch = useDispatch()

  const cardBrandPath = brand => {
    if (brand == 'visa') {
      return brandVisaPath
    }

    if (brand == 'mastercard') {
      return brandMastercardPath
    }

    return ''
  }

  const cardExpiryDate = date => {
    const parsedDate = parse(`01/${date}`, 'dd/MM/yy', new Date())

    return format(parsedDate, "'Expira em' MMMM 'de' yyyy", { locale: ptBR })
  }

  const handleDeleteCard = async id => {
    confirm({
      description: 'Deseja deletar esse cartão?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(destroyCard(id))
    })
  }

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {creditCards?.map(card => (
            <Grid item>
              <Box
                className={classes.creditCard}
                style={{
                  opacity: card?.active ? 1 : 0.5,
                }}
              >
                <Box className={classes.creditCardInfo}>
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '4px',
                        border: `1px solid ${colors.graylight}`,
                        fontSize: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {cardBrandPath(card?.brand) ? (
                        <img
                          src={cardBrandPath(card?.brand)}
                          alt="Card Brand"
                          width="24px"
                          height="12px"
                          style={{ objectFit: 'contain' }}
                        />
                      ) : (
                        <Icon
                          path={mdiCreditCard}
                          size={1}
                          color={colors.primary}
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{ padding: '0 8px' }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            fontSize: '14px',
                            fontWeight: '700',
                            textTransform: 'capitalize',
                          }}
                        >
                          {`${card?.brand} ... ${card?.last_four_digits}`}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            fontSize: '11px',
                            lineHeight: '14px',
                          }}
                        >
                          {cardExpiryDate(card?.expiry)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.creditCardActions}>
                  {renderRoute(
                    ['gerency-update-laundry-client'],
                    permissions
                  ) && (
                    <Button
                      className={classes.btnCreditCardActions}
                      component={Link}
                      to={`/subscription/credit-card/${card?.id}`}
                    >
                      Editar
                    </Button>
                  )}
                  <Box
                    style={{
                      borderLeft: `1px solid ${colors.primary}`,
                      width: '2px',
                      height: '100%',
                    }}
                  />
                  <Button
                    className={classes.btnCreditCardActions}
                    onClick={() => handleDeleteCard(card?.id)}
                  >
                    Excluir
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}

          <Grid item>
            <Box className={[classes.creditCard, classes.newCreditCard]}>
              {renderRoute(['gerency-update-laundry-client'], permissions) && (
                <IconButton
                  className={classes.btnNewCreditCard}
                  component={Link}
                  to="/subscription/credit-card/new"
                >
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Icon path={mdiPlus} size={1} color={colors.primary} />
                    </Grid>
                    <Grid item xs={12}>
                      Adicionar novo cartão
                    </Grid>
                  </Grid>
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const RenderCreditCard = ({
  card,
  form,
  handleSelectCreditCard = () => {},
}) => {
  const classes = useStyles()

  const cardBrandPath = brand => {
    if (brand == 'visa') {
      return brandVisaPath
    }

    if (brand == 'mastercard') {
      return brandMastercardPath
    }

    return ''
  }

  const cardExpiryDate = date => {
    const parsedDate = parse(`01/${date}`, 'dd/MM/yy', new Date())

    return format(parsedDate, "'Expira em' MMMM 'de' yyyy", { locale: ptBR })
  }

  return (
    <Box
      className={classes.creditCard}
      style={{
        opacity: form?.card_id == card?.id ? 1 : 0.3,
      }}
      onClick={() => handleSelectCreditCard(card?.id)}
    >
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              border: `1px solid ${colors.graylight}`,
              fontSize: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {cardBrandPath(card?.brand) ? (
              <img
                src={cardBrandPath(card?.brand)}
                alt="Card Brand"
                width="24px"
                height="12px"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <Icon path={mdiCreditCard} size={1} color={colors.primary} />
            )}
          </Grid>
          <Grid item xs>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ padding: '0 8px' }}
            >
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
              >
                {`${card?.brand} ... ${card?.last_four_digits}`}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '11px',
                  lineHeight: '14px',
                }}
              >
                {cardExpiryDate(card?.expiry)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CardsForm
