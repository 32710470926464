import {
  EDIT_LAUNDRY_CLIENT,
  FETCH_LAUNDRY_CLIENT,
} from './LaundryClientActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingClient: null,
  plans: [],
  statuses: [],
  signature_status: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY_CLIENT.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        filters: action.payload.data.filters,
        // page: action.payload.meta.current_page,
        // totalSize: action.payload.meta.total,
        // sizePerPage: action.payload.meta.per_page,
        plans: action.payload.plans,
        statuses: action.payload.statuses,
        signature_status: action.payload.signature_status,
      }
    case EDIT_LAUNDRY_CLIENT.SUCCESS:
      return {
        ...state,
        editingClient: action.payload,
      }
    default:
      return state
  }
}

export const getLaundryClientListData = state => {
  return {
    items: state.laundryClient.items,
    // page: state.laundryClient.page,
    // sizePerPage: state.laundryClient.sizePerPage,
    // totalSize: state.laundryClient.totalSize,
    plans: state.laundryClient.plans,
  }
}

export const getEditingLaundryClient = state => {
  return state.client.editingClient
}
