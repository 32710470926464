/* eslint-disable camelcase */
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  InputBase,
  Switch,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
// import { ColorBox } from 'material-ui-color'
import React, { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import NumberFormat from '../../components/NumberFormat'
import SelectField from '../../components/SelectField'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import colors from '../../../assets/colors'
import { is } from 'date-fns/locale'
import { isThisMinute } from 'date-fns'
import { SNACKBAR } from '../../main/MainActions'

function Form(props) {
  const { classes, data, onClose, onSubmit } = props
  const measureType = useSelector(state => state.serviceType.measureType)
  const attendanceType = useSelector(state => state.serviceType.attendanceType)
  const serviceCategory = useSelector(
    state => state.serviceType.serviceCategory
  )

  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    status: 1,
    measureType: '',
    attendanceType: '',
    serviceCategory: '',
    technician_value: '',
    technician_percent: '',
    execution_time: '',
    default_value: '',
    color: '',
    edit_final_value: 0,
    ignore_finishing_status: 0,
  })

  const styles = filterStylesComponents()

  const parseValue = value => {
    if (!value) return

    return parseFloat(value?.replace(/\./g, '')?.replace(',', '.'))
  }

  const updateField = React.useCallback(name => e => {
    const { value } = e.target
    updateForm({
      ...form,
      [name]: value,
    })

    if (name === 'default_value') {
      updateForm({
        ...form,
        default_value: value,
        technician_value: (form.technician_percent / 100) * value,
      })
    }

    if (name === 'technician_percent') {
      updateForm({
        ...form,
        technician_percent: value,
        technician_value: (value / 100) * form?.default_value,
      })
    }

    if (name === 'technician_value') {
      updateForm({
        ...form,
        technician_percent: (value * 100) / form.default_value,
        technician_value: value,
      })
    }
  })

  const updateFun = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    if (form?.execution_time < '00:00:01') {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: 'O tempo de execução não pode ser 00:00!',
        },
      })
    }

    const send = { ...form, status: form.status || 0 }

    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  const readOnly = useMemo(() => form.id === 1, [form.id])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextValidator
            validators={['required']}
            errorMessages={['Campo Obrigatório']}
            onChange={updateField('name')}
            label="Nome"
            fullWidth
            value={form.name}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField
            options={measureType}
            setForm={updateFun}
            data={form.measureType}
            name="measureType"
            label="Tipo de Cobrança"
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField
            options={attendanceType}
            setForm={updateFun}
            data={form.attendanceType}
            name="attendanceType"
            label="Tipo de Atendimento"
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField
            options={serviceCategory}
            setForm={updateFun}
            data={form.serviceCategory}
            name="serviceCategory"
            label="Categoria"
          />
        </Grid>

        <Grid item xs={6}>
          <InputMask
            mask="99:99"
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            maskChar=""
            value={form.execution_time}
            onChange={updateField('execution_time')}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="Tempo para execução"
                fullWidth
                helperText="O tempo não pode ser menor que 00:01"
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={6}>
          <NumberFormat
            label="Valor base"
            value={form.default_value}
            onChange={updateField('default_value')}
            className={classes.formControl}
            decimalScale={2}
            decimalSeparator=","
            customInput={TextField}
            thousandSeparator="."
            displayType="input"
          />
        </Grid>

        <Grid item xs={3}>
          <NumberFormat
            value={form.technician_percent}
            onChange={updateField('technician_percent')}
            className={classes.formControl}
            decimalScale={2}
            decimalSeparator=","
            customInput={TextField}
            label="% Técnico"
            thousandSeparator="."
            displayType="input"
            endAdornment="%"
          />
        </Grid>

        <Grid item xs={3}>
          <NumberFormat
            className={classes.formControl}
            value={form.technician_value}
            onChange={updateField('technician_value')}
            label="Valor Técnico"
            startAdornment="R$"
            customInput={TextField}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            displayType="input"
          />
        </Grid>

        <Grid item xs={6} container alignItems="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(form.edit_final_value)}
                onChange={e =>
                  updateForm({ ...form, edit_final_value: e.target.checked })
                }
                value={form.edit_final_value}
              />
            }
            label="Valor final editável na venda?"
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems="flex-end"
          justifyContent="space-between"
          style={{ marginTop: '2.5em' }}
        >
          <Grid item>
            <Switch
              checked={Boolean(form.status)}
              onChange={e => {
                updateForm({ ...form, status: !form.status })
              }}
            />
            Ativar tipo de serviço
          </Grid>

          <Grid item>
            <Button className={styles.btnSalvar} color="primary" type="submit">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}

export default Form
