import { Button, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import { useConfirm } from 'material-ui-confirm'
import {
  edit,
  fetchLaundryClients,
  resetSignature,
} from '../../LaundryClientActions'

const Signature = ({ form, handleChange, setForm }) => {
  const filterStyles = filterStylesComponents()
  const plans = useSelector(state => state.laundryClient.plans)
  const statuses = useSelector(state => state.laundryClient.statuses)
  const signatureStatus = useSelector(
    state => state.laundryClient.signature_status
  )
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const initPlan = () => {
    if (plans?.length > 1) return

    setForm(prev => ({
      ...prev,
      client_plan_id: plans[0]?.id,
    }))
  }

  useEffect(() => {
    if (!plans?.length || form?.client_plan_id) return

    initPlan()
  }, [plans, form])

  const confirmCopy = id => {
    confirm({
      description: `Deseja realmente liberar a lavanderia para editar a assinatura?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      await dispatch(resetSignature(id))
      dispatch(edit(id))
      // const item = historic.find(x => x.id === id)
      // const { data } = await dispatch(edit(item.id))
      // fillNewServices(data)
      // setOpen(false)
      // dispatch({
      //   type: SNACKBAR.SOFTSUCCESS,
      //   success: { message: 'A Nova OS foi populada com os itens' },
      // })
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel id="demo-simple-select-required-label">
              Plano
            </InputLabel>

            <Select
              value={form?.client_plan_id}
              fullWidth
              name="client_plan_id"
              required
              onChange={handleChange}
              readOnly={plans?.length <= 1}
            >
              <MenuItem value="">Selecione...</MenuItem>
              {plans?.map((plan, index) => (
                <MenuItem key={index} value={plan?.id}>
                  {plan?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel id="demo-simple-select-required-label">
              Status da Assinatura
            </InputLabel>

            <Select
              value={form?.signature_status_id}
              fullWidth
              readOnly
              disabled
            >
              {signatureStatus?.map((signature, index) => (
                <MenuItem key={index} value={signature?.var_id}>
                  {signature?.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel id="demo-simple-select-required-label">
              Status
            </InputLabel>

            <Select
              value={form?.status_id}
              key={`input-status_id-${form?.status_id}`}
              fullWidth
              onChange={handleChange}
              name="status_id"
            >
              {statuses?.map((signature, index) => (
                <MenuItem key={index} value={signature?.var_id}>
                  {signature?.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {form.signature_status_id == 40 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <button
                type="button"
                className={filterStyles.btnAdd}
                onClick={() => {
                  confirmCopy(form?.id)
                }}
              >
                Liberar edição da assinatura
              </button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Signature
