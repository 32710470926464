/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { Grid, Paper, Typography } from '@material-ui/core'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import { isCpfCnpjValid } from '../../../../util/cpf_cnpj_validator'
import colors from '../../../../assets/colors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '.5rem',
  },
})

const FormGroupUser = ({ form, handleChange = () => {} }) => {
  const classes = useStyles()

  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: 'Digite um CPF',
  })

  const [values, setValues] = useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleValidCpfCnpj = e => {
    const { value } = e.target

    if (value.length == 14) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CPF inválido',
        })
      }
    }
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={2} item xs={12}>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label="Nome"
            name="manager_name"
            value={form?.manager_name}
            onChange={handleChange}
            required
            fullWidth
            className={classes?.textField}
          />
        </Grid>

        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label="Email"
            type="email"
            name="manager_email"
            value={form?.manager_email}
            onChange={handleChange}
            required
            fullWidth
            className={classes?.textField}
          />
        </Grid>

        <Grid item sm={12} xs={12} md={6}>
          <ReactInputMask
            mask="999.999.999-99"
            maskChar=""
            name="manager_cpf"
            value={form?.manager_cpf}
            onChange={handleChange}
            onBlur={handleValidCpfCnpj}
            required
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="CPF"
                fullWidth
                error={cpfCnpj?.error}
                helperText={cpfCnpj?.text}
              />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item sm={12} xs={12} md={6}>
          <TextField
            name="manager_password"
            label="Password"
            fullWidth
            required={!form?.manager_has_password}
            onChange={handleChange}
            type={values.showPassword ? 'text' : 'password'}
            helperText={
              form?.manager_has_password && 'Gerente já possuí senha preenchida'
            }
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FormGroupUser
