import { Grid, makeStyles, Box, Typography } from '@material-ui/core'
import React from 'react'
import CountUp from 'react-countup'

import { useSelector } from 'react-redux'
import colors from '../../../../assets/colors'

const useStyles = makeStyles({
  card: {
    border: '1px solid',
    borderColor: colors.graylight,
    borderRadius: '8px',
    color: colors.primary,
    padding: '4px 8px',

    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  title: {
    color: colors.primary,
    fontWeight: '700',
    fontSize: '20px',
  },
})

const PlanDetails = ({}) => {
  const classes = useStyles()

  const planDetails = useSelector(state => state.subscription.planDetails)

  const contUpMoney = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimal=","
        decimals={2}
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={6} md={6} xl={3}>
        <Box className={classes.card}>
          <Typography className={classes.title}>Plano</Typography>
          {planDetails?.name || '-'}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={2}>
        <Box className={classes.card}>
          <Typography className={classes.title}>Valor</Typography>
          {contUpMoney(planDetails?.price || 0)}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <Box className={classes.card}>
          <Typography className={classes.title}>Máximo de usuários</Typography>
          {planDetails?.max_clients || '-'}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <Box className={classes.card}>
          <Typography className={classes.title}>Dia de vencimento</Typography>
          {planDetails?.due_day || '-'}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4} xl={3}>
        <Box className={classes.card}>
          <Typography className={classes.title}>Status</Typography>
          {planDetails?.status?.value}
        </Box>
      </Grid>
    </Grid>
  )
}

export default PlanDetails
