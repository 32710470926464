import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PencilIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchLaundryClients } from '../LaundryClientActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import Icon from '@mdi/react'
import { mdiAlert } from '@mdi/js'
import colors from '../../../assets/colors'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.client.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchLaundryClients(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>ID</StyledTableCellHeader>
              <StyledTableCellHeader>Nome</StyledTableCellHeader>
              <StyledTableCellHeader>
                Ativos / Máximo de Clientes
              </StyledTableCellHeader>
              <StyledTableCellHeader>Plano</StyledTableCellHeader>
              <StyledTableCellHeader>Status</StyledTableCellHeader>
              <StyledTableCellHeader>
                Status da Assinatura
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Faturas pendentes
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Ações
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item.id}</StyledTableCellBody>
                <StyledTableCellBody>{item.name}</StyledTableCellBody>
                <StyledTableCellBody>
                  {item.active_clients} / {item.max_clients}
                </StyledTableCellBody>
                <StyledTableCellBody>
                  {item.clientPlan?.name || '-'}
                </StyledTableCellBody>
                <StyledTableCellBody>{item.statusString}</StyledTableCellBody>
                <StyledTableCellBody>
                  {item?.signature_status_path || '-'}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.pending_invoice ? (
                    <IconButton
                      style={{
                        backgroundColor: colors.bgInfo,
                        padding: '4px',
                        borderRadius: '4px',
                      }}
                    >
                      <Icon path={mdiAlert} size={0.8} color={colors.info} />
                    </IconButton>
                  ) : (
                    '-'
                  )}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <IconButton
                    component={Link}
                    to={`/laundry-clients/${item.id}/edit`}
                  >
                    <PencilIcon fontSize="small" />
                  </IconButton>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      /> */}
    </>
  )
}

export default List
