/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SettingsIcon from '@material-ui/icons/Settings'

import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { hasRole, renderRoute } from '../../util/utils'

import { fetchAuthenticatedUser } from '../auth/AuthActions'
import { redirectByPermission } from '../main/routes'
import {
  mdiCalendarClock,
  mdiCalendarMultiselectOutline,
  mdiFileDocumentEditOutline,
  mdiFinance,
  mdiMonitorDashboard,
  mdiPackageVariantClosed,
  mdiPhoneClock,
  mdiViewDashboardEditOutline,
} from '@mdi/js'

import ItemMenu from './ListItem'
import Icon from '@mdi/react'
import colors from '../../assets/colors'
import { Divider } from '@material-ui/core'

function Icons(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role) || {}

  useEffect(() => {
    dispatch(fetchAuthenticatedUser())
  }, [])

  const redirection = redirectByPermission(permissions)

  const {
    classes,
    open,
    openRelatory,
    openFinance,
    openCrm,
    openDashboard,
    handleDrawerClose,
    handleDrawerOpen,
    openDrawer,
    handleClick,
    handleClickCrm,
    handleClickRelatory,
    handleClickFinance,
    handleClickDashboard,
  } = props

  function renderIcons() {
    const items = [
      {
        route: '/knowWay',
        title: 'Como Conheceu',
        role: ['read-know-way'],
      },
      // {
      //   route: '/edit-laundry',
      //   title: 'Meus dados',
      //   role: ['gerency-update-laundry-client'],
      // },
      {
        route: '/users',
        title: 'Usuários',
        role: ['read-users'],
      },
      {
        route: '/clients',
        title: 'Clientes',
        role: ['read-client'],
      },
      {
        route: '/laundry-clients',
        title: 'Cliente Lavanderias',
        role: ['read-laundry-client'],
      },
      {
        route: '/client-plan',
        title: 'Planos de Clientes',
        role: ['client-plan-list'],
      },
      {
        route: '/service-category',
        title: 'Categorias de serviço',
        rolesThatCantSee: ['administrador'],
        role: ['read-service-categories'],
      },
      {
        route: '/service-type',
        title: 'Tipos de serviço',
        rolesThatCantSee: ['administrador'],
        role: ['read-service-types'],
      },
      {
        route: '/payment-method',
        title: 'Formas de pagamento',
        role: ['read-payment-methods'],
      },
    ]

    const sorted = items.sort((a, b) => a.title.localeCompare(b.title))

    const filteredItems = sorted?.filter(item => {
      if (item.rolesThatCantSee !== undefined && role[0] !== undefined) {
        return !item.rolesThatCantSee.includes(role[0].name)
      }
      return item
    })

    return filteredItems?.map((item, i) => {
      return (
        (permissions.find(x => x.name == item.role[0]) ||
          (item.userRoles &&
            item.userRoles.filter(userRole => role.name === userRole).length >
              0)) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            // icon={item.icon}
            title={item.title}
            open={openDrawer}
          />
        )
      )
    })
  }

  function renderRelatories() {
    const items = [
      {
        route: '/relatory-birth',
        title: 'Aniversariantes',
        rolesThatCantSee: ['administrador'],
        role: ['read-birth-relatory'],
      },
      {
        route: '/relatory-clients',
        title: 'Clientes',
        rolesThatCantSee: ['administrador'],
        role: ['read-clients-relatory'],
      },
      {
        route: '/relatory-prospects',
        title: 'Prospects',
        rolesThatCantSee: ['administrador'],
        role: ['read-prospects-relatory'],
      },
      {
        route: '/relatory-services',
        title: 'Atendimentos',
        rolesThatCantSee: ['administrador'],
        role: ['read-attendances-relatory'],
      },
      {
        route: '/stock',
        title: 'Estoque',
        rolesThatCantSee: ['gerente_lavanderia'],
        role: [],
      },
      {
        route: '/relatory-schedules',
        title: 'Agendamento',
        rolesThatCantSee: ['administrador'],
        role: ['read-schedule-relatory'],
      },
      {
        route: '/relatory-stock',
        title: 'Estoque',
        rolesThatCantSee: ['administrador'],
        role: ['read-team-produtivity-relatory'],
      },
      {
        route: '/relatory-commission',
        title: 'Comissão',
        rolesThatCantSee: ['administrador'],
        role: ['read-team-produtivity-relatory'],
      },
      {
        route: '/relatory-service-category',
        title: 'Categoria de Serviços',
        rolesThatCantSee: ['administrador'],
        role: ['read-service-category-relatory'],
      },
      {
        route: '/relatory-team-produtivity',
        title: 'Produção Equipe',
        rolesThatCantSee: ['administrador'],
        role: ['read-team-produtivity-relatory'],
      },
      {
        route: '/laundry-client-billings',
        title: 'Faturas',
        role: ['laundry-client-billing-list'],
      },
      // {
      //   route: '/relatory-financial',
      //   title: 'Financeiro',
      //   rolesThatCantSee: ['administrador'],
      //   role: ['read-financial-relatory'],
      // },
      // {
      //   route: '/bank-report',
      //   title: 'Bancário',
      //   role: ['read-bank-report'],
      // },
    ]

    const sorted = items.sort((a, b) => a.title.localeCompare(b.title))

    const filteredItems = sorted.filter(item => {
      if (item.rolesThatCantSee !== undefined && role[0] !== undefined) {
        return !item.rolesThatCantSee.includes(role[0].name)
      }
      return item
    })

    return filteredItems.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            title={item.title}
            // icon={}
            open={openDrawer}
          />
        )
      )
    })
  }

  function renderDashboard() {
    const items = [
      {
        route: '/dashboard-financial',
        title: 'Financeiro',
        // rolesThatCantSee: ['administrador'],
        role: ['read-financial-dashboard'],
      },
      {
        route: '/lead',
        title: 'Leads',
        // rolesThatCantSee: ['administrador'],
        role: ['read-lead-dashboard'],
      },
      {
        route: '/',
        title: 'Itens',
        rolesThatCantSee: ['administrador'],
        role: ['read-dashboard'],
      },
    ]

    const sorted = items.sort((a, b) => a.title.localeCompare(b.title))

    const filteredItems = sorted.filter(item => {
      if (item.rolesThatCantSee !== undefined && role[0] !== undefined) {
        return !item.rolesThatCantSee.includes(role[0].name)
      }
      return item
    })

    return filteredItems.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            title={item.title}
            // icon={}
            open={openDrawer}
          />
        )
      )
    })
  }

  function renderFinance() {
    const items = [
      {
        route: '/supplier',
        title: 'Contatos Financeiros',
        role: ['read-supplier'],
      },
      {
        route: '/billing-type',
        title: 'Centro de custo',
        role: ['read-billing-type'],
      },
      {
        route: '/billing',
        title: 'Lançamento de contas',
        role: ['read-billing'],
      },
      {
        route: '/dre',
        title: 'DRE',
        role: ['read-billing'],
      },
      {
        route: '/bank',
        title: 'Bancos',
        role: ['read-bank'],
      },
    ]

    const sorted = items.sort((a, b) => a.title.localeCompare(b.title))

    return sorted.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            title={item.title}
            open={openDrawer}
          />
        )
      )
    })
  }

  function renderCrm() {
    const items = [
      {
        route: '/crm/panel',
        title: 'Painel CRM',
        role: ['read-attendances'],
      },
      {
        route: '/crm/triggers',
        title: 'Gatilhos',
        role: ['read-attendances'],
      },
      {
        route: '/crm/reports/leads',
        title: 'Relatório de Leads',
        role: ['read-attendances'],
      },
      {
        route: '/crm/reports/gerency',
        title: 'Relatório Gerencial',
        role: ['read-attendances'],
      },
    ]

    const sorted = items.sort((a, b) => a.title.localeCompare(b.title))

    return sorted.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            title={item.title}
            open={openDrawer}
          />
        )
      )
    })
  }

  useEffect(() => {
    if (!openDrawer && openDashboard) {
      handleClickDashboard()
    }

    if (!openDrawer && openCrm) {
      handleClickCrm()
    }

    if (!openDrawer && openFinance) {
      handleClickFinance()
    }

    if (!openDrawer && openRelatory) {
      handleClickRelatory()
    }

    if (!openDrawer && open) {
      handleClick()
    }
  }, [openDrawer])

  const handleClickFinancial = url => {
    window.location.href = url
  }

  return (
    <List className={classes.listDrawer}>
      {redirection}

      {hasRole('gerente_lavanderia', role) && (
        <>
          <ItemMenu
            // route="/"
            handle={() => {
              handleClickDashboard()

              if (!openDrawer) {
                handleDrawerOpen()
              }
            }}
            icon={
              <Icon
                path={mdiViewDashboardEditOutline}
                color={colors.primary}
                size={1.1}
              />
            }
            title="Dashboard"
            expand={openDashboard ? <ExpandLess /> : <ExpandMore />}
            open={openDrawer}
          />

          <Collapse
            in={openDashboard && openDrawer}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {renderDashboard()}
            </List>
          </Collapse>

          <Divider />
        </>
      )}

      {role[0] !== undefined &&
        role[0].name !== 'administrador' &&
        renderRoute(['create-attendances'], permissions) && (
          <>
            <ItemMenu
              route="/attendance"
              handle={handleDrawerClose}
              icon={
                <Icon path={mdiPhoneClock} color={colors.primary} size={1.1} />
              }
              title="Atendimentos"
              open={openDrawer}
            />
            <Divider />
          </>
        )}

      {role[0] !== undefined &&
        role[0].name !== 'administrador' &&
        renderRoute(['read-schedule'], permissions) && (
          <>
            <ItemMenu
              route="/calendar"
              handle={handleDrawerClose}
              icon={
                <Icon
                  path={mdiCalendarMultiselectOutline}
                  color={colors.primary}
                  size={1.1}
                />
              }
              title="Calendário"
              open={openDrawer}
            />

            <Divider />
          </>
        )}

      {/* {role[0] !== undefined &&
        role[0].name !== 'administrador' &&
        renderRoute(['read-finance-module'], permissions) && (
          <ItemMenu
            icon={<AccountBalanceWalletIcon color="secondary" />}
            title="Gerenciar Pagamentos"
            handle={() => {
              handleClickFinancial('/financial/')
            }}
          />
        )} */}

      {permissions[0]?.name !== 'read-finance-module' && (
        <>
          <ItemMenu
            // handle={handleClick}
            handle={() => {
              handleClick()

              if (!openDrawer) {
                handleDrawerOpen()
              }
            }}
            icon={
              <Icon
                path={mdiFileDocumentEditOutline}
                color={colors.primary}
                size={1.1}
              />
            }
            open={openDrawer}
            title="Cadastros"
            expand={open ? <ExpandLess /> : <ExpandMore />}
          />

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIcons()}
            </List>
          </Collapse>

          <Divider />
        </>
      )}

      {role[0] !== undefined &&
        role[0].name !== 'administrador' &&
        renderRoute(['create-attendances'], permissions) && (
          <>
            <ItemMenu
              route="/stock"
              handle={handleDrawerClose}
              icon={
                <Icon
                  path={mdiPackageVariantClosed}
                  color={colors.primary}
                  size={1.1}
                />
              }
              title="Estoque"
              open={openDrawer}
            />
            <Divider />
          </>
        )}

      {permissions[0]?.name !== 'read-finance-module' && (
        <>
          <ItemMenu
            handle={() => {
              handleClickRelatory()

              if (!openDrawer) {
                handleDrawerOpen()
              }
            }}
            icon={
              <Icon path={mdiCalendarClock} color={colors.primary} size={1.1} />
            }
            title="Relatórios"
            expand={openRelatory ? <ExpandLess /> : <ExpandMore />}
            open={openDrawer}
          />

          <Collapse in={openRelatory} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderRelatories()}
            </List>
          </Collapse>

          <Divider />
        </>
      )}

      {permissions?.filter(p => p.name == 'read-finance-module')?.length >
        0 && (
        <>
          <ItemMenu
            handle={() => {
              handleClickFinance()

              if (!openDrawer) {
                handleDrawerOpen()
              }
            }}
            icon={<Icon path={mdiFinance} size={1.1} color={colors.primary} />}
            title="Módulo Financeiro"
            expand={openFinance ? <ExpandLess /> : <ExpandMore />}
            open={openDrawer}
          />

          <Collapse in={openFinance} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderFinance()}
            </List>
          </Collapse>

          <Divider />
        </>
      )}

      {role[0] !== undefined &&
        role[0].name !== 'administrador' &&
        renderRoute(['create-attendances'], permissions) && (
          <>
            <ItemMenu
              handle={() => {
                handleClickCrm()

                if (!openDrawer) {
                  handleDrawerOpen()
                }
              }}
              icon={
                <Icon
                  path={mdiMonitorDashboard}
                  size={1.1}
                  color={colors.primary}
                />
              }
              title="CRM"
              expand={openCrm ? <ExpandLess /> : <ExpandMore />}
              open={openDrawer}
            />

            <Collapse in={openCrm} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderCrm()}
              </List>
            </Collapse>

            <Divider />
          </>
        )}

      {renderRoute(
        ['change-status-settings', 'update-doc-model', 'download-apk'],
        permissions
      ) && (
        <ItemMenu
          route="/settings"
          handle={handleDrawerClose}
          icon={<SettingsIcon className={classes.iconRI} size={25} />}
          title="Configurações"
          open={openDrawer}
        />
      )}
    </List>
  )
}

export default React.memo(Icons)
