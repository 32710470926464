import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../assets/colors'
import BaseFilter from '../../../components/hooks/BaseFilter'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import SelectField from '../../components/SelectField'
import { mdiFilterMultiple, mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { fetchInvoice, fetchInvoices } from '../SubscriptionActions'

const Filter = ({}) => {
  const styles = filterStylesComponents()

  const dispatch = useDispatch()

  const paymentMethods = useSelector(state => state.subscription.paymentMethods)
  const invoiceStatuses = useSelector(
    state => state.subscription.invoiceStatuses
  )

  const [form, setForm] = useState({
    payment_method_id: '',
    status_id: '',
    date_start: null,
    date_end: null,
  })

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    dispatch(fetchInvoices(form))
  }

  return (
    <>
      <BaseFilter>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={5} lg={3}>
              <TextField
                select
                name="payment_method_id"
                label="Método de pagamento"
                fullWidth
                value={form.payment_method_id}
                onChange={handleChange}
              >
                <MenuItem value="">Selecione...</MenuItem>

                {paymentMethods?.map((paymentMethod, index) => (
                  <MenuItem value={paymentMethod?.var_id} key={index}>
                    {paymentMethod?.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={2} sm={5}>
              <TextField
                select
                name="status_id"
                label="Status"
                fullWidth
                value={form.status_id}
                onChange={handleChange}
              >
                <MenuItem value="">Selecione...</MenuItem>

                {invoiceStatuses?.map((invoiceStatus, index) => (
                  <MenuItem value={invoiceStatus?.var_id} key={index}>
                    {invoiceStatus?.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={3} sm={5}>
              <KeyboardDatePicker
                clearable
                value={form.date_start}
                label="Data de Vencimento Inicial"
                fullWidth
                clearLabel="Limpar"
                autoOk
                inputVariant="standard"
                onChange={date =>
                  setForm({
                    ...form,
                    date_start: date,
                  })
                }
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item md={3} sm={5}>
              <KeyboardDatePicker
                clearable
                value={form.date_end}
                label="Data de Vencimento Final"
                fullWidth
                clearLabel="Limpar"
                autoOk
                inputVariant="standard"
                onChange={date =>
                  setForm({
                    ...form,
                    date_end: date,
                  })
                }
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item xs container alignItems="center">
              <Button className={styles.btnMoreFilters} onClick={handleSubmit}>
                <Icon
                  path={mdiFilterMultiple}
                  size={1}
                  color={colors.secondary}
                />
                {` Filtrar`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilter>
    </>
  )
}

export default Filter
