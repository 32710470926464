/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { exportFormatedMoney } from '../../../util/utils'
import colors from '../../../assets/colors'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@mdi/react'
import {
  mdiArrowDown,
  mdiChevronDown,
  mdiChevronUp,
  mdiCurrencyUsd,
} from '@mdi/js'
import { fetchBilling } from '../../billing/BillingActions'

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  status: {
    borderRadius: '5px',
    width: '82px',
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFF',
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '12px',
    padding: '4px 0',
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  missingProduct: {
    color: colors.error,
    gap: '.4em',
  },
  tooltip: {
    backgroundColor: colors.error,
    fontSize: '14px',
    boxShadow: '0px 0px 7px 0px #00000040',
    '& .arrow': {
      color: colors.error,
    },
  },
  centerCost: {
    height: '26px',
    width: '2px',
    borderRadius: '5px',
  },
  table: {
    animation: '$fadeIn .8s ease-in-out',
    maxHeight: 'calc(100vh - 360px)',
    overflowY: 'auto',
  },
}))

const List = ({ type = 'Despesa' }) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const billingTypes = useSelector(state => state.billing.billingTypes)
  const filters = useSelector(state => state.billing.filters)
  const items = useSelector(state => state.billing.items)

  const [open, setOpen] = useState(false)

  const handleCalculateTotal = billingTypeId => {
    const billingTypeItems = items?.filter(
      item => item?.billing_type?.id == billingTypeId
    )

    const total = billingTypeItems?.reduce(
      (accumulator, currentItem) =>
        Number(accumulator) + Number(currentItem.value || 0),
      0
    )

    return exportFormatedMoney(total)
  }

  const hanldeOpenDetails = billingTypeId => {
    const form = {
      ...filters,
      billing_type: !open ? billingTypeId : '',
    }
    setOpen(!open)

    dispatch(fetchBilling(form))
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left" style={{ width: '3%' }} />
              <StyledTableCellHeader>Centro de Custo</StyledTableCellHeader>
              <StyledTableCellHeader>Total Acumulado</StyledTableCellHeader>
              <StyledTableCellHeader align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {billingTypes
              ?.filter(billingType =>
                filters?.billing_type
                  ? billingType?.id == filters?.billing_type
                  : billingType
              )
              ?.map((billingType, index) => (
                <TableRow key={index}>
                  <StyledTableCellBody align="left">
                    <Box
                      className={classes.centerCost}
                      style={
                        billingType?.type == 1
                          ? { backgroundColor: colors.error }
                          : { backgroundColor: colors.sucess }
                      }
                    />
                  </StyledTableCellBody>
                  <StyledTableCellBody>{billingType?.name}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {handleCalculateTotal(billingType?.id)}
                  </StyledTableCellBody>
                  <StyledTableCellBody align="right">
                    <Button
                      type="button"
                      onClick={() => hanldeOpenDetails(billingType?.id)}
                    >
                      <Icon
                        path={open ? mdiChevronUp : mdiChevronDown}
                        size={0.8}
                      />
                    </Button>
                  </StyledTableCellBody>
                </TableRow>
              ))}

            <TableRow>
              <StyledTableCellBody
                align="left"
                style={{ padding: '4px 2px', display: 'flex' }}
              >
                <Icon
                  path={mdiCurrencyUsd}
                  color={type == 'Despesa' ? colors.error : colors.sucess}
                  size={1}
                />
              </StyledTableCellBody>
              <StyledTableCellBody
                style={{
                  fontWeight: 'bold',
                  color: colors.primary,
                  textTransform: 'uppercase',
                }}
              >
                Total
              </StyledTableCellBody>
              <StyledTableCellBody
                style={{ fontWeight: 'bold', color: colors.primary }}
              >
                {exportFormatedMoney(
                  items.reduce(
                    (accumulator, currrentItem) =>
                      Number(accumulator) + Number(currrentItem?.value || 0),
                    0
                  )
                )}
              </StyledTableCellBody>
              <StyledTableCellBody align="right" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {open && (
        <TableContainer
          className={classes.table}
          style={{ position: 'relative' }}
        >
          <Table size="small" style={{ position: 'relative' }}>
            <TableHead style={{ position: 'sticky', top: 0 }}>
              <TableRow>
                <StyledTableCellHeader align="left" style={{ width: '3%' }} />
                <StyledTableCellHeader>Data</StyledTableCellHeader>
                <StyledTableCellHeader>Data do Pagamento</StyledTableCellHeader>
                <StyledTableCellHeader>Data da Baixa</StyledTableCellHeader>
                <StyledTableCellHeader>
                  Data do Vencimento
                </StyledTableCellHeader>
                <StyledTableCellHeader>
                  Contato Financeiro
                </StyledTableCellHeader>
                <StyledTableCellHeader>Descrição</StyledTableCellHeader>
                <StyledTableCellHeader>Banco</StyledTableCellHeader>
                <StyledTableCellHeader>
                  Forma de Pagamento
                </StyledTableCellHeader>
                <StyledTableCellHeader>Valor</StyledTableCellHeader>

                <StyledTableCellHeader>Status</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((billing, index) => (
                <TableRow key={index}>
                  <StyledTableCellBody align="left">
                    <Box
                      className={classes.centerCost}
                      style={
                        billing?.billing_type?.type == 1
                          ? { backgroundColor: colors.error }
                          : { backgroundColor: colors.sucess }
                      }
                    />
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {billing?.created_at}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{billing?.payment}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {billing?.payment_date}
                  </StyledTableCellBody>
                  <StyledTableCellBody>{billing?.due_date}</StyledTableCellBody>
                  <StyledTableCellBody>{billing?.contact}</StyledTableCellBody>
                  <StyledTableCellBody>
                    {billing?.description ?? '/ /'}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {billing?.bank_name}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {billing?.payment_method?.name}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    {exportFormatedMoney(billing?.value || 0)}
                  </StyledTableCellBody>
                  <StyledTableCellBody>
                    <Box
                      className={classes.status}
                      style={{
                        backgroundColor: billing.is_paid
                          ? colors.sucess
                          : colors.error,
                      }}
                    >
                      {billing?.is_paid ? 'Pago' : 'Em aberto'}
                    </Box>
                  </StyledTableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default List
