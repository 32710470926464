/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  DialogActions,
  FormControlLabel,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, setHours } from 'date-fns'
import axios from 'axios'
import ReactInputMask from 'react-input-mask'

import config from '../../../config'
import { SNACKBAR } from '../../main/MainActions'
import SelectField from '../../components/SelectField'
import styles from '../../../resources/theme/users'
import { fetchLaundryClients, findCep } from '../LaundryClientActions'
import {
  mdiAccount,
  mdiProgressUpload,
  mdiSignature,
  mdiWashingMachine,
} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import ufs from '../../../util/uf'
import FormGroupLaundry from './Forms/Laundry'
import FormGroupUser from './Forms/User'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import Signature from './Forms/Signature'

const Form = ({ data, onClose, onSubmit, notShowPost, formEdit }) => {
  const dispatch = useDispatch()

  const classes = filterStylesComponents()

  const [value, setValue] = useState(1)

  const [form, setForm] = useState({
    id: '',
    name: '',
    email: '',

    cep: '',
    street: '',
    number: '',
    neighborhood: '',
    complement: '',
    city: '',
    uf: '',
    phone: '',
    cpf_cnpj: '',
    description: '',

    logo: '',

    manager_id: null,
    manager_cpf: '',
    manager_email: '',
    manager_name: '',
    manager_password: '',
    manager_has_password: false,

    client_plan_id: '',
    status_id: 1,
    status: 1,
    signature_status_id: 10,
  })

  const init = async () => {
    await dispatch(fetchLaundryClients())
  }

  const initForm = () => {
    setForm(prev => ({
      ...prev,
      ...data,
    }))
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit && onSubmit(form)
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (formEdit && !data) return

    initForm()
  }, [formEdit, data])

  // useEffect(() => {
  //   console.log(form)
  // }, [form])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Tabs>
          <Tab
            label="Dados da lavanderia"
            value={value}
            tabIndex={1}
            icon={mdiWashingMachine}
            setValue={setValue}
            removeNavigation={false}
          />
          <Tab
            label="Dados da Assinatura"
            value={value}
            tabIndex={2}
            setValue={setValue}
            icon={mdiSignature}
            removeNavigation={false}
          />
          <Tab
            label="Dados do gerente"
            value={value}
            setValue={setValue}
            tabIndex={3}
            icon={mdiAccount}
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {value == 1 && (
                <FormGroupLaundry
                  form={form}
                  setForm={setForm}
                  handleChange={handleChange}
                />
              )}

              {value == 2 && (
                <Signature
                  form={form}
                  handleChange={handleChange}
                  setForm={setForm}
                />
              )}

              {value == 3 && (
                <FormGroupUser form={form} handleChange={handleChange} />
              )}
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                color="primary"
                type="submit"
                className={classes.btnSalvar}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Form)
