import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SyncIcon from '@material-ui/icons/Sync'
import classNames from 'classnames'

import { fetchDashboard, eraseDashboardFilters } from '../HomeActions'
import styles from '../../../resources/theme/global'
import { hasRole, renderRoute } from '../../../util/utils'

import Cards from './Cards'
import Filter from './Filters'
import colors from '../../../assets/colors'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'
import Chart from 'react-google-charts'
import PieChart from './PieChart'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { fetchPlanDetails } from '../../subscription/SubscriptionActions'

const useStyles = makeStyles({
  chartBox: {
    width: '100%',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '24px',
  },
  chartBoxHeader: {
    fontSize: '20px',
    color: colors.primary,
    lineHeight: '20px',
  },
  teste: {
    border: '1px solid red',
  },
})

function Home(props) {
  const filterStyles = filterStylesComponents()

  const refreshing = useSelector(state => state.dashboard.refreshing)
  const readyItems = useSelector(state => state.dashboard.readyCount)
  const readyItemsLaundry = useSelector(
    state => state.dashboard.readyLaundryCount
  )

  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role) || {}

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboard())
    dispatch(eraseDashboardFilters())
    dispatch(fetchPlanDetails())

    return () => {
      dispatch(eraseDashboardFilters())
    }
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Dashboard Itens
            </Typography>
          </Grid>

          {hasRole('gerente_lavanderia', role) && (
            <Grid item xs container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <IconButton
                  variant="contained"
                  component={Link}
                  className={filterStyles.btnAdd}
                  to="/details/ready"
                >
                  OSS PRONTAS PARA ENTREGA
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Cards {...props} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Home)
