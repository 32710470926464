/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchBilling } from '../../billing/BillingActions'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiMenuLeft,
  mdiMenuRight,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
  mdiSearchWeb,
} from '@mdi/js'
import colors from '../../../assets/colors'
import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'
import { ptBR } from 'date-fns/locale'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'row',
  },
  period: {
    padding: '4px 12px',
    border: `1px solid ${colors.graylight}`,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
    },
    cursor: 'pointer',
  },
  borderRight: {
    borderRadius: '0px 5px 5px 0',
  },
  borderLeft: {
    borderRadius: '5px 0 0 5px',
  },
  seletedPeriod: {
    color: '#FFF',
    backgroundColor: colors.primary,
  },
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
    margin: '0 4px',
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

export default function Filter(props) {
  const dispatch = useDispatch()
  const classes = filterStylesComponents()
  const filterPeriodClasses = useStyles()

  const statuses = useSelector(state => state.billing.statuses)
  const billingTypes = useSelector(state => state.billing.billingTypes)
  const paymentMethods = useSelector(state => state.billing.paymentMethods)
  const banks = useSelector(state => state.billing.banks)
  const billingTypesGeneric = ['Despesa', 'Receita']
  const filters = useSelector(state => state.billing.filters)
  const [open, setOpen] = useState(false)

  const { typeGeneric } = props

  const [filterKey, setFilterKey] = useState(0)

  const [currentForm, setCurrentForm] = useState({
    person: '',
    date: new Date(startOfMonth(new Date())),
    date_end: new Date(endOfMonth(new Date())),
    date_payment: null,
    date_end_payment: null,
    status: '',
    billing_type: '',
    payment_method: '',
    service_id: '',
    company: '',
    bank: '',
    billing_type_generic: typeGeneric,
    type: typeGeneric,
    reference_month: '',
    period: '',
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchBilling(currentForm))

    handleOpen()
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...currentForm,
      [name]: value,
    })
  }

  const handleChangePeriodOnFilter = type => {
    let form = {}

    if (type == 'prev') {
      // periodo mensal
      if (filterKey == 0) {
        form = {
          ...currentForm,
          date: new Date(subMonths(currentForm?.date, 1)),
          date_end: new Date(subMonths(currentForm?.date_end, 1)),
        }
      }

      // periodo anual
      if (filterKey == 1) {
        form = {
          ...currentForm,
          date: new Date(subYears(startOfYear(currentForm?.date), 1)),
          date_end: new Date(subYears(endOfYear(currentForm?.date_end), 1)),
        }
      }

      setCurrentForm(prev => ({
        ...prev,
        ...form,
      }))
    }

    if (type == 'next') {
      // periodo mensal
      if (filterKey == 0) {
        form = {
          ...currentForm,
          date: new Date(addMonths(currentForm?.date, 1)),
          date_end: new Date(addMonths(currentForm?.date_end, 1)),
        }
      }

      // periodo anual
      if (filterKey == 1) {
        form = {
          ...currentForm,
          date: new Date(addYears(startOfYear(currentForm?.date), 1)),
          date_end: new Date(addYears(endOfYear(currentForm?.date_end), 1)),
        }
      }

      setCurrentForm(prev => ({
        ...prev,
        ...form,
      }))
    }

    dispatch(fetchBilling(form))
  }

  const handleSelectFilterPeriod = key => {
    setFilterKey(key)
  }

  useEffect(() => {
    dispatch(fetchBilling(currentForm))
  }, [])

  useEffect(() => {
    const form = {
      ...currentForm,
      billing_type_generic: typeGeneric,
      type: typeGeneric,
      billing_type: '',
    }

    dispatch(fetchBilling(form))
  }, [typeGeneric])

  useEffect(() => {
    setCurrentForm(prev => ({
      ...prev,
      ...filters,
      date_end: currentForm.date_end,
      date_start: currentForm?.date_start,
    }))
  }, [filters])

  useEffect(() => {
    dispatch(fetchBilling(currentForm))
  }, [currentForm?.status, currentForm?.billing_type])

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3} xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            value={currentForm.billing_type}
            name="billing_type"
            label="Filtre por centro de custo"
            fullWidth
            onChange={handleChange}
          >
            <MenuItem value="">Selecione...</MenuItem>
            {billingTypes?.map(billingType => (
              <MenuItem value={billingType?.id} key={billingType?.id}>
                {billingType?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            value={currentForm.status}
            name="status"
            label="Filtre por status"
            fullWidth
            onChange={handleChange}
          >
            <MenuItem value="0">Selecione...</MenuItem>
            {statuses?.map(status => (
              <MenuItem value={status?.id} key={status?.id}>
                {status?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} className={classes.areaButtons}>
        <IconButton className={classes.btnMoreFilters} onClick={handleOpen}>
          <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
          Filtros
        </IconButton>

        <Grid item>
          <Box className={filterPeriodClasses.box}>
            <Box className={filterPeriodClasses.filterBox}>
              <Box className={filterPeriodClasses.periodFilter}>
                <IconButton
                  onClick={() => {
                    handleChangePeriodOnFilter('prev')
                  }}
                  className={filterPeriodClasses.periodFilterBtn}
                >
                  <Icon path={mdiMenuLeft} size={0.7} />
                </IconButton>

                <Typography style={{ textTransform: 'capitalize' }}>
                  {filterKey === 0
                    ? format(currentForm?.date, "MMMM '-' yyyy", {
                        locale: ptBR,
                      })
                    : format(currentForm?.date, 'yyyy', {
                        locale: ptBR,
                      })}
                </Typography>

                <IconButton
                  onClick={() => {
                    handleChangePeriodOnFilter('next')
                  }}
                  className={filterPeriodClasses.periodFilterBtn}
                >
                  <Icon path={mdiMenuRight} size={0.7} />
                </IconButton>
              </Box>
            </Box>

            <Box
              className={[
                filterPeriodClasses.period,
                filterPeriodClasses.borderLeft,
                filterKey === 1 ? filterPeriodClasses.seletedPeriod : '',
              ]}
              onClick={() => handleSelectFilterPeriod(1)}
            >
              Ano
            </Box>
            <Box
              className={[
                filterPeriodClasses.period,
                filterPeriodClasses.borderRight,

                filterKey === 0 ? filterPeriodClasses.seletedPeriod : '',
              ]}
              onClick={() => handleSelectFilterPeriod(0)}
            >
              Mês
            </Box>
          </Box>
        </Grid>
      </Grid>

      <BaseFilterModal
        title="FILTRO"
        hiddenHelpButton
        open={open}
        handleOpen={handleOpen}
      >
        <Grid container xs={12} item direction="row" style={{ width: '420px' }}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> uma conta
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={e =>
                    setCurrentForm({ ...currentForm, person: e.target.value })
                  }
                  label="Contato"
                  fullWidth
                  value={currentForm.person}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="billing_type"
                  label="Centro de custo"
                  value={currentForm.billing_type}
                  fullWidth
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {billingTypes?.map(billingType => (
                    <MenuItem value={billingType?.id} key={billingType?.id}>
                      {billingType?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="status"
                  label="Status"
                  fullWidth
                  value={currentForm.status}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {statuses?.map(status => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="payment_method"
                  label="Método de pagamento"
                  fullWidth
                  value={currentForm.payment_method}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {paymentMethods?.map(method => (
                    <MenuItem value={method?.id} key={method?.id}>
                      {method?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="bank"
                  label="Banco"
                  fullWidth
                  value={currentForm.bank}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {banks?.map(bank => (
                    <MenuItem value={bank?.id} key={bank?.id}>
                      {bank?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date}
                  label="Data de Vencimento Inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_end}
                  label="Data de Vencimento Final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_end: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_payment}
                  label="Data de Pagamento Inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_payment: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_end_payment}
                  label="Data de Pagamento Final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_end_payment: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: '1em' }}
                container
                alignItems="center"
                justifyContent="center"
              >
                <IconButton onClick={onSubmit} className={classes.btnSalvar}>
                  <Icon path={mdiMagnify} size={1} />
                  Pesquisar
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </Grid>
  )
}
