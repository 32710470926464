/* eslint-disable eqeqeq */
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import colors from '../../../assets/colors'
import {
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns'
import Icon from '@mdi/react'
import { mdiMenuLeft, mdiMenuRight } from '@mdi/js'
import { ptBR } from 'date-fns/locale'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'row',
  },
  period: {
    padding: '4px 12px',
    border: `1px solid ${colors.graylight}`,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
    },
    cursor: 'pointer',
  },
  borderRight: {
    borderRadius: '0px 5px 5px 0',
  },
  borderLeft: {
    borderRadius: '5px 0 0 5px',
  },
  seletedPeriod: {
    color: '#FFF',
    backgroundColor: colors.primary,
  },
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
    margin: '0 4px',
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

const PeriodFilter = ({
  filters,
  setFilters,
  path,
  onChangePeriod = key => {},
}) => {
  const classes = useStyles()
  const [key, setKey] = useState(0)

  const handleSelect = key => {
    setKey(key)
  }

  const [date, setDate] = useState(new Date())

  const handleChangeMonth = type => {
    if (type == 'prev') {
      setDate(new Date(subMonths(date, 1)))
    }

    if (type == 'next') {
      setDate(new Date(addMonths(date, 1)))
    }
  }

  useEffect(() => {
    if (!filters) return

    if (key == 0) {
      setFilters({
        ...filters,
        date_start: format(startOfDay(new Date()), 'yyyy-MM-dd'),
        date_end: format(endOfDay(new Date()), 'yyyy-MM-dd'),
      })
    }

    if (key == 1) {
      setFilters({
        ...filters,
        date_start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
        date_end: format(endOfWeek(new Date()), 'yyyy-MM-dd'),
      })
    }

    if (key == 2) {
      setFilters({
        ...filters,
        date_start: format(startOfMonth(date), 'yyyy-MM-dd'),
        date_end: format(endOfMonth(date), 'yyyy-MM-dd'),
      })
    }

    onChangePeriod && onChangePeriod(key)
  }, [key, date])

  return (
    <Box className={classes.box}>
      {/* só aparece quando o filtro é mensal */}
      {key == 2 && (
        <Box className={classes.filterBox}>
          <Box className={classes.periodFilter}>
            <IconButton
              onClick={() => {
                handleChangeMonth('prev')
              }}
              className={classes.periodFilterBtn}
            >
              <Icon path={mdiMenuLeft} size={0.7} />
            </IconButton>

            <Typography style={{ textTransform: 'capitalize' }}>
              {format(date, "MMMM '-' yyyy", { locale: ptBR })}
            </Typography>

            <IconButton
              onClick={() => {
                handleChangeMonth('next')
              }}
              className={classes.periodFilterBtn}
            >
              <Icon path={mdiMenuRight} size={0.7} />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box
        className={[
          classes.period,
          classes.borderLeft,
          key === 2 ? classes.seletedPeriod : '',
        ]}
        onClick={() => handleSelect(2)}
      >
        Mês
      </Box>
      <Box
        className={[classes.period, key === 1 ? classes.seletedPeriod : '']}
        onClick={() => handleSelect(1)}
      >
        Semana
      </Box>
      <Box
        className={[
          classes.period,
          classes.borderRight,
          key === 0 ? classes.seletedPeriod : '',
        ]}
        onClick={() => handleSelect(0)}
      >
        Dia
      </Box>
    </Box>
  )
}

export default PeriodFilter
