import axios from 'axios'

import consts from '../../config'
import createAsyncConst from '../../util/createAsyncConst'
// eslint-disable-next-line import/no-cycle
import action, { actionFailed } from '../../util/action'

export const AUTHENTICATE = createAsyncConst('AUTHENTICATE')
export const AUTHENTICATED_USER = createAsyncConst('AUTHENTICATED_USER')
export const LOGOUT = createAsyncConst('LOGOUT')
export const FORGOT_PASSWORD = createAsyncConst('FORGOT_PASSWORD')
export const RESET_PASSWORD = createAsyncConst('RESET_PASSWORD')
export const VALIDATE_PASSWORD = createAsyncConst('VALIDATE_PASSWORD')
export const CHECK_AUTH = 'CHECK_AUTH'

export function login(values) {
  values.grant_type = consts.API_GRANT
  values.client_id = consts.API_CLIENT
  values.client_secret = consts.API_SECRET
  // eslint-disable-next-line no-use-before-define
  return submit(values, `${consts.API_URL}/auth/login`)
  // return submit(values, `${consts.API_URL}/oauth/token`)
}

export function fetchAuthenticatedUser() {
  return dispatch => {
    dispatch(action(AUTHENTICATED_USER.ACTION))
    axios
      .get(`${consts.API_URL}/user/init`)
      .then(resp => resp.data)
      .then(data => dispatch(action(AUTHENTICATED_USER.SUCCESS, data)))
      .catch(e =>
        dispatch(action(AUTHENTICATED_USER.FAILURE, null, e.response.data))
      )
  }
}

export function forgotPassword(values) {
  return dispatch => {
    dispatch(action(FORGOT_PASSWORD.ACTION))
    return axios
      .post(`${consts.API_URL}/user/forgot-password`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FORGOT_PASSWORD.SUCCESS, data))
        return true
      })
      .catch(e => {
        dispatch(actionFailed(FORGOT_PASSWORD, e))
        return false
      })
  }
}

export function newPassword(values) {
  return dispatch => {
    dispatch(action(RESET_PASSWORD.ACTION))
    return axios
      .post(`${consts.API_URL}/user/reset-password`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(RESET_PASSWORD.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(RESET_PASSWORD, e)))
  }
}

export function validatePassword(values) {
  return dispatch => {
    dispatch(action(VALIDATE_PASSWORD.ACTION))
    return axios
      .post(`${consts.API_URL}/user/validate-password`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(VALIDATE_PASSWORD.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(VALIDATE_PASSWORD, e)))
  }
}

export function submit(values, url) {
  return dispatch => {
    dispatch(action(AUTHENTICATE.ACTION))
    return axios
      .post(url, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(AUTHENTICATE.SUCCESS, data))
        fetchAuthenticatedUser()(dispatch)
        // return data
      })
      .catch(e => {
        dispatch(action(AUTHENTICATE.FAILURE, null, { message: '' }))
        return e
      })
  }
}

export async function loginUsingId(id) {
  const response = await axios
    .get(`${consts.API_URL}/loginUsingId/${id}`)
    .then(resp => resp.data)
    .then(data => {
      return data
    })
    .catch(e => actionFailed(AUTHENTICATE, e))
  return response
}

export function logout() {
  return dispatch => {
    dispatch(action(LOGOUT.ACTION))
    return axios
      .post(`${consts.API_URL}/logout`)
      .then(data => {
        dispatch(action(LOGOUT.SUCCESS, data.data))
      })
      .catch(e => dispatch(action(LOGOUT.FAILURE, null, e.response.data)))
  }
}

export function checkAuth() {
  return action(CHECK_AUTH)
}
