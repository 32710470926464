import { makeStyles } from '@material-ui/core'
import { mdiAlert } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../assets/colors'
import BasePopUp from '../../../components/hooks/BasePopUp'
import { fetchPlanDetails } from '../../subscription/SubscriptionActions'

const useStyles = makeStyles({
  alertButton: {
    backgroundColor: colors.bgInfo,
    color: colors.info,
    border: 'none',
    outline: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
})

const Alert = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const laundryClient = useSelector(state => state.subscription.laundryClient)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    dispatch(fetchPlanDetails())
  }, [])

  return (
    <>
      {laundryClient?.signature_status_id &&
        laundryClient?.signature_status_id != 40 && (
          <button onClick={handleOpen} className={classes.alertButton}>
            <Icon path={mdiAlert} size={1} />
          </button>
        )}
      <BasePopUp
        message={laundryClient?.signature_status_path}
        title="Pendência na Assinatura"
        open={open}
        handleOpen={handleOpen}
        type="info"
      />
    </>
  )
}

export default Alert
