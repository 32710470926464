import {
  Grid,
  makeStyles,
  Box,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Button,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'

import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../assets/colors'
import { renderRoute } from '../../../../util/utils'
import { useConfirm } from 'material-ui-confirm'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { mdiCreditCard, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import {
  destroyCard,
  fetchPlanDetails,
  submitSubscription,
  updateSubscriptionPlan,
} from '../../SubscriptionActions'
import brandVisaPath from '../../../../../public/card_brands/visa_brand.png'
import brandMastercardPath from '../../../../../public/card_brands/mastercard_brand.png'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import { SNACKBAR } from '../../../main/MainActions'

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  divider: {
    borderLeft: `1px solid ${colors.graylight}`,

    [theme.breakpoints.down('xs')]: {
      borderLeft: `0`,
      borderBottom: `1px solid ${colors.graylight}`,
    },
  },
  creditCard: {
    border: `1px solid ${colors.graylight}`,
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    minHeight: '40px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '1 !important',
    },
    animation: '$fadeIn .4s ease-in-out',
  },
  creditCardInfo: {
    width: '100%',
    height: '80%',
    padding: '12px',
    color: colors.gray,
  },
  creditCardActions: {
    backgroundColor: colors.graylight,
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
    margin: '0',
    borderRadius: '0 0 10px 10px',
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  newCreditCard: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.graylight,
  },
  btnNewCreditCard: {
    borderRadius: 0,
    backgroundColor: 'inherit',
    width: '100%',
    height: '90px',
    fontSize: '11px',
    color: colors.gray,
    lineHeight: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: '.6',
    },
  },
  btnCreditCardActions: {
    width: 'auto',
    padding: 0,
    margin: 0,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    textTransform: 'capitalize',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
      backgroundColor: colors.graylight,
    },
  },
}))

const PaymentMethod = ({}) => {
  const filterStyles = filterStylesComponents()

  const planDetails = useSelector(state => state.subscription.planDetails)
  const paymentMethods = useSelector(state => state.subscription.paymentMethods)
  const creditCards = useSelector(state => state.subscription.creditCards)
  const permissions = useSelector(state => state.auth.permissions)

  const confirm = useConfirm()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    plan_id: '',
    name: '',
    price: 0,
    maxClients: '',
    due_day: '5',
    payment_method_id: '',
    card_id: '',
  })

  const [readOnly, setReadOnly] = useState(false)

  const handleSelectCreditCard = id => {
    setForm({
      ...form,
      card_id: id,
    })
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const hanldeSubmit = async () => {
    if (!form?.payment_method_id) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Selecione o método de pagamento!' },
      })
    }

    if (form?.payment_method_id == 10 && !form?.card_id) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Selecione o cartão!' },
      })
    }

    if (form?.payment_method_id == 20 && !form?.due_day) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Digite o dia de vencimento!' },
      })
    }

    console.log(form)
    try {
      if (form?.status?.var_id == 40) {
        const resp = await dispatch(
          updateSubscriptionPlan(form, planDetails?.id)
        )

        if (resp) {
          dispatch(fetchPlanDetails())
        }
        return
      }

      const resp = await dispatch(submitSubscription(form))

      if (resp) {
        dispatch(fetchPlanDetails())
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!planDetails) return

    setForm(prev => ({
      ...prev,
      ...planDetails,
    }))
    setReadOnly(planDetails?.status?.var_id == 30)
  }, [planDetails])

  console.log(form)

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={6} lg={4}>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '12px',
            border: '1px solid',
            borderColor: colors.graylight,
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          <Grid item xs={12}>
            <TextField
              select
              name="payment_method_id"
              fullWidth
              value={form?.payment_method_id}
              onChange={handleChange}
              disabled={readOnly}
            >
              <MenuItem value="">Selecione...</MenuItem>
              {paymentMethods?.map((paymentMethod, index) => (
                <MenuItem value={paymentMethod?.var_id} key={index}>
                  {paymentMethod?.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* cartao de credito */}
          {form?.payment_method_id == 10 && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {creditCards?.map(card => (
                  <Grid item xs={4} key={card?.id}>
                    <RenderCreditCard
                      card={card}
                      form={form}
                      handleSelectCreditCard={handleSelectCreditCard}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {/* boleto */}
          {form?.payment_method_id == 20 && (
            <Grid item xs={12}>
              <TextField
                name="due_day"
                value={form?.due_day}
                onChange={handleChange}
                type="number"
                fullWidth
                disabled={readOnly}
                label="Dia de Vencimento"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <button
                className={filterStyles.btnSalvar}
                onClick={hanldeSubmit}
                disabled={readOnly}
              >
                Salvar
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentMethod

const RenderCreditCard = ({
  card,
  form,
  handleSelectCreditCard = () => {},
}) => {
  const classes = useStyles()

  const cardBrandPath = brand => {
    if (brand == 'visa') {
      return brandVisaPath
    }

    if (brand == 'mastercard') {
      return brandMastercardPath
    }

    return ''
  }

  const cardExpiryDate = date => {
    const parsedDate = parse(`01/${date}`, 'dd/MM/yy', new Date())

    return format(parsedDate, "'Expira em' MMMM 'de' yyyy", { locale: ptBR })
  }

  return (
    <Box
      className={classes.creditCard}
      style={{
        opacity: form?.card_id == card?.id ? 1 : 0.3,
      }}
      onClick={() => handleSelectCreditCard(card?.id)}
    >
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            style={{
              width: '40px',
              height: 'auto',
              maxHeight: '40px',
              borderRadius: '4px',
              border: `1px solid ${colors.graylight}`,
              fontSize: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {cardBrandPath(card?.brand) ? (
              <img
                src={cardBrandPath(card?.brand)}
                alt="Card Brand"
                width="24px"
                height="12px"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <Icon path={mdiCreditCard} size={1} color={colors.primary} />
            )}
          </Grid>
          <Grid item xs>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ padding: '0 8px' }}
            >
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
              >
                {`${card?.brand} ... ${card?.last_four_digits}`}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '11px',
                  lineHeight: '14px',
                }}
              >
                {cardExpiryDate(card?.expiry)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
