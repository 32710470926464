/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import { FaChevronDown } from 'react-icons/fa'
import fontSizes from '../../../assets/fontSizes'
import colors from '../../../assets/colors'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { hasRole } from '../../../util/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlanDetails } from '../../subscription/SubscriptionActions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
    borderRadius: '10px',
    color: colors.textPrimary,
    fontSize: fontSizes.text,
    position: 'relative',
  },
  buttons: {
    color: colors.primary,
  },
  ref: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    top: '4px',
    left: '32%',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '6px 12px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
}))

const MenulistComposition = ({ userName }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const roles = useSelector(state => state.auth.role)
  const laundryClient = useSelector(state => state.subscription.laundryClient)

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    dispatch(fetchPlanDetails())
  }, [])

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.buttons}
      >
        {userName}
        <FaChevronDown style={{ marginLeft: '4px' }} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuList}
                >
                  <MenuItem className={classes.ref} />
                  {hasRole('gerente_lavanderia', roles) && (
                    <>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to="/edit-laundry"
                        className={classes.menuItem}
                      >
                        Dados da Lavanderia
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        className={classes.menuItem}
                        to="/subscription"
                        disabled={laundryClient?.signature_status_id == 10}
                      >
                        Minha Assinatura
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to="/laundry-eletronic-invoice"
                        className={classes.menuItem}
                      >
                        Geração de NFSe
                      </MenuItem>
                    </>
                  )}

                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    className={classes.menuItem}
                    disabled
                  >
                    Alterar Senha
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    className={classes.menuItem}
                    disabled
                  >
                    Suporte
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    className={classes.menuItem}
                    disabled
                  >
                    Ajuda
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default MenulistComposition
