import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { addMilliseconds, addMinutes, format, min } from 'date-fns'

function Metro2({ updateChildren, chave, item, styles }) {
  const multiplyTime = (hours, minutes, factor) => {
    // Converte o tempo para minutos totais
    const totalMinutes = Number(hours * 60) + Number(minutes)

    const multipliedMinutes = totalMinutes * factor

    const result = new Date(0, 0, 0)
    const finalDate = addMinutes(result, multipliedMinutes)

    // Formata o resultado no formato desejado (HH:mm:ss)
    return format(finalDate, 'HH:mm:ss')
  }

  const updateFinalTime = () => {
    const base = item?.options?.base
    const altura = item?.options?.altura

    const multiplyFactor = Number(base) * Number(altura)

    const [hours, minutes, seconds] = item?.execution_time?.split(':')

    const finalTime = multiplyTime(hours, minutes, multiplyFactor)

    updateChildren(chave, finalTime, 'final_time')
  }

  const handleChangeMetrics = e => {
    e.preventDefault()
    const { name, value } = e.target

    const inputValue = value?.replace(',', '.')

    updateChildren(chave, inputValue, name, true)

    // no cliente
    if (item?.attendanceType?.id == 2) {
      updateFinalTime()
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={4}>
          <TextField
            name="base"
            type="number"
            className={styles?.input}
            onChange={handleChangeMetrics}
            label="Base"
            validators={['required']}
            errorMessages={['Campo necessário']}
            fullWidth
            value={item.options.base ? item.options.base : ''}
            style={{ maxWidth: '100px' }}
          />
        </Grid>
        {/* X */}
        <Grid item xs={4}>
          <TextField
            name="altura"
            type="number"
            className={styles?.input}
            onChange={handleChangeMetrics}
            label="Altura"
            validators={['required']}
            errorMessages={['Campo necessário']}
            fullWidth
            value={item.options.altura ? item.options.altura : ''}
            style={{ maxWidth: '100px' }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Metro2
