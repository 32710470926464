import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiBarcodeScan, mdiFilePdfBox } from '@mdi/js'
import copy from 'copy-to-clipboard'
import { SNACKBAR } from '../../main/MainActions'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    gap: '2px',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    justifyContent: 'space-between',
    fontSize: '20px',
  },
})

const InvoiceDetails = ({ open, handleClose }) => {
  const classes = useStyles()
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.subscription.showInvoice)

  const hanldePdf = url => {
    window.open(url, '_blank')
  }

  const handleCopy = async value => {
    try {
      await copy(value)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'Código copiado com sucesso!' },
      })
    } catch (error) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Erro ao copiar o codigo!' },
      })
    }
  }

  return (
    <BaseFilterModal open={open} hiddenHelpButton handleOpen={handleClose}>
      <Grid item xs={12} style={{ width: '350px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className={classes.title}>
              <Typography
                variant="h4"
                style={{
                  fontSize: '20px',
                }}
                color={colors.primary}
              >
                Detalhes da Fatura
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: '20px',
                  color: colors.textPrimary,
                }}
              >
                R$ {invoice?.value}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                gap: '4px',
                color: colors.textPrimary,
              }}
            >
              Vencimento: {invoice?.vencimento}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                gap: '4px',
                color: colors.textPrimary,
              }}
            >
              Plano: {invoice?.pagarme_plan}
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            className={filterStyles.areaButtons}
          >
            <IconButton
              onClick={() => hanldePdf(invoice?.pdf)}
              className={filterStyles.btnAdd}
            >
              <Icon path={mdiFilePdfBox} size={1} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleCopy(invoice?.codigo)
              }}
              className={filterStyles.btnAdd}
            >
              <Icon path={mdiBarcodeScan} size={1} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default InvoiceDetails
