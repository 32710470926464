/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import Transiction from '../../components/Transition'
import { edit, update } from '../LaundryClientActions'
import Form from './Form'
import { Grid, makeStyles } from '@material-ui/core'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import colors from '../../../assets/colors'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

const useStyles = makeStyles({
  root: {
    padding: '16px 12px',
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  subTitle: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  btnSave: {
    borderRadius: '20px',
    color: '#FFF',
    backgroundColor: colors.primary,
    fontSize: '12px',
    textTransform: 'capitalize',
    width: '101px',
    padding: '4px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

function EditType(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const client = useSelector(state => state.laundryClient.editingClient)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/laundry-clients')
      }
    })
  }

  const onClose = () => {
    props.history.push('/laundry-clients')
  }

  return (
    <>
      <BaseCreatePage
        page="Cliente Lavanderia"
        path="Editar"
        pageLink="laundry-clients"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              alignContent="center"
              justifyContent="flex-start"
              className={classes.subTitle}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Utilize os campos abaixo para editar o cliente lavanderia
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Form
              data={client}
              onClose={onClose}
              onSubmit={onSubmit}
              formEdit
            />
          </Grid>
        </Grid>
      </BaseCreatePage>
    </>
  )
}

export default withStyles(styles)(EditType)
